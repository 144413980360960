<template>
  <div class="card-filter-section">
    <div class="d-flex align-items-center flex-wrap justify-content-between">
      <div class="d-flex align-items-center justify-content-start flex-wrap">
        <p class="filter-title">
          Filters:
        </p>
        <div>
          <custom-dropdown
            v-model="sort"
            :options="sortingOrder"
            :hide-close="true"
            :search-box="false"
            label="Sorting"
            icon="EditIcon"
          />
        </div>
        <div class="w-lg-100">
          <custom-dropdown
            v-model="type"
            :options="projectTypeList"
            label="Project Type"
            icon="EditIcon"
            multiple
          />
        </div>

        <div class="">
          <custom-dropdown
            v-model="status"
            :options="projectStatusList"
            label="Status"
            icon="EditIcon"
            multiple
          />
        </div>
        <div
          v-if="userInfo && userInfo.role == 'SA'"
          class=""
        >
          <custom-dropdown
            v-model="budget"
            :options="budgetList"
            :search-box="false"
            label="Budget"
            icon="EditIcon"
          />
        </div>

        <div v-if="userInfo && userInfo.role == 'SA'">
          <custom-dropdown
            v-model="user"
            :options="$store.state.app.usersList"
            label="Member"
            icon="EditIcon"
          />
        </div>
        <div v-if="userInfo && userInfo.role == 'SA'">
          <custom-dropdown
            v-model="client_id"
            :options="clientListOption"
            label="Client"
            icon="EditIcon"
          />
        </div>
        <OptionDropdown
          v-model="perPage"
          :options="[8,12,16,20]"
        />
      </div>
      <div class="d-flex align-items-center">
        <b-input-group
          class="input-group-merge search-project"
          style="min-width: 250px; max-width: 250px; width: 250px"
        >
          <b-form-input
            v-model="search"
            placeholder="Search by Project Name"
            @keyup.13="applyFilter"
          />

          <b-input-group-append is-text>
            <feather-icon
              v-if="search"
              icon="XIcon"
              @click="clearFilter()"
            />
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>

        <div class="ml-1">
          <button
            class="apply-filter filterRedesign mr-1"
            :disabled="validateFilter"
            @click="applyFilter"
          >
            Apply Filter
          </button>
          <button
            class="apply-filter-clear filterRedesign"
            @click="clearFilter"
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customDropdown from '@/components/dropdown/customDropdown.vue'
import { eventBus } from '@/main'
import OptionDropdown from '../../components/optiondropdown/OptionDropdown.vue'

export default {
  name: 'FilterSection',
  components: {
    customDropdown,
    OptionDropdown,
  },
  props: ['perPages'],
  data() {
    return {
      type: [],
      status: ['in_planning', 'in_progress'],
      budget: null,
      user: null,
      search: null,
      users: [],
      client_id: null,
      perPage: (this.$store.state.app.projectFilterList && this.$store.state.app.projectFilterList.perPage) || 8,
      sortingOrder: [
        { value: 'asc', label: 'A-Z' },
        { value: 'desc', label: 'Z-A' },
        { value: 'last_accessed', label: 'Last Accessed' },
      ],
      sort: 'asc',
    }
  },
  computed: {
    budgetList() {
      return [
        { value: 'over_budget', label: 'Over Budget Projects' },
        { value: 'under_budget', label: 'Under Budget Projects' },
      ]
    },

    clientListOption() {
      this.$store.state.app.clientList.forEach(element => {
        element.value = element.id
        element.label = element.name
      })

      return this.$store.state.app.clientList
    },

    validateFilter() {
      return false
    },
  },
  watch: {
    perPages(val) {
      this.perPage = val
    },
    search(nv) {
      if ((nv && nv.length >= 3) || !nv) {
        const filter = {
          type: [],
          status: [],
          budget: null,
          user: null,
          search: nv || null,
          perPage: this.perPage,
        }
        eventBus.$emit('projectSearch', filter)
      }
    },
    perPage(nv, old) {
      const newPerPage = this.$store.state.app.projectFilterList && this.$store.state.app.projectFilterList.perPage ? this.$store.state.app.projectFilterList.perPage : 8
      if (nv != newPerPage) {
        this.currentPage = 1
        this.applyFilter()
      }
    },
  },
  mounted() {
    if (this.userInfo && this.userInfo.role == 'SA') {
      this.clientList()
      if (!this.$store.state.app.usersList.length) {
        this.userList()
      }
    }

    if (this.$store.state.app.projectFilterList && Object.keys(this.$store.state.app.projectFilterList).length > 0) {
      const filter = this.$store.state.app.projectFilterList
      this.type = filter.type || []
      this.status = filter.status || ['in_planning', 'in_progress']
      this.budget = filter.budget || null
      this.user = filter.user || null
      this.search = filter.search || null
      this.client_id = filter.client || null
      this.perPage = filter.perPage || 8
      this.sort = filter.sort || 'asc'
    } else {
      this.applyFilter()
    }
  },
  methods: {
    /* client list */
    async clientList() {
      const input = {
        sort_field: 'name',
        sort_order: 'asc',
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/client',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.$store.state.app.clientList = data.clients
      }
    },

    applyFilter() {
      const filter = {
        type: this.type,
        status: this.status,
        budget: this.budget,
        user: this.user,
        search: this.search,
        client: this.client_id,
        perPage: this.perPage,
        sort: this.sort,
      }
      this.$store.commit('app/UPDATE_PROJECT_FILTER_LIST', filter)
      eventBus.$emit('projectFilters', filter)
    },
    clearFilter() {
      this.perPage = 8
      if (
        this.type === []
        && this.status === []
        && this.budget === null
        && this.user === null
        && this.search === null
      ) {
        return
      }
      this.type = []
      this.status = ['in_planning', 'in_progress']
      this.budget = null
      this.user = null
      this.search = null
      this.client_id = null
      this.sort = 'asc'
      const filter = {
        type: [],
        status: ['in_planning', 'in_progress'],
        budget: null,
        user: null,
        search: null,
        perPage: 8,
        currentPage: 1,
        sort: 'asc',
      }
      this.$store.commit('app/UPDATE_PROJECT_FILTER_LIST',filter)
      eventBus.$emit('projectFilters', filter)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables/_variables.scss";

.card-filter-section {
  background-color: $white;
  margin: 10px 0px 25px 0px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  padding: 10px 15px;
  .filter-title {
    color: $logo-color;
    font-size: 2.25 * $rem;
    font-weight: $fw500;
  }
  .selectmember {
    cursor: pointer;
    margin-left: 20px;
    background-color: transparent;
    border-color: transparent;
    color: $logo-color;
    display: flex;
    align-items: center;
    position: relative;
    @media (max-width: map-get($mediaSizes , "xl")) {
      margin-left: 18px;
    }
    @media (max-width: map-get($mediaSizes , "lg")) {
      margin-left: 0;
      margin: 7px 0;
    }
    span {
      font-size: 1.75 * $rem;
      font-weight: $fw500;
      text-transform: capitalize;
      line-height: 23px;
    }
    .feather-edit {
      margin-right: 8px;
    }
    .feather-chevron-down {
      margin-left: 8px;
    }
    .badge-pill-filter {
      position: absolute;
      top: -12px;
      right: 13px;
      display: flex;
      height: 20px;
      width: 20px;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: rgba(255, 159, 67, 0.7);
    }
  }
  .radio-dropdown {
    position: absolute;
    z-index: 111;
  }
  .apply-filter {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: $white;
    padding: 8px 11px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;
    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }
  .apply-filter-clear {
    border: 1px solid $secondary-color;
    color: $secondary-color;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;
    background-color: transparent;
    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }
    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }
  .search-project {
    border-radius: 5px;
    color: $dark-gray;
    &:focus-visible {
      outline: $primary-color;
    }
    .form-control {
      border-color: $input-outline;
      padding: 6px 0 6px 20px;
      color: $logo-color !important;
      height: 33px;
      &::placeholder {
        color: $dark-gray;
        font-size: 1.5 * $rem;
        font-weight: $fw500;
      }
    }
  }
}
</style>
