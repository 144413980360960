<template>
  <div
    v-if="projectDtl"
    class="h-100"
  >
    <b-card class="newproject-card projectListinfo mb-0 h-100 p-0">
      <div
        class="newproject-title-section d-flex justify-content-between align-items-center"
        @click="openNewTab"
      >
        <p class="newproject-title">
          {{ projectDtl.name }}
          <span
            v-if="notificationCount(projectDtl.id) > 0"
            class="notificationBtn"
            style="cursor: default"
            @click.stop
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="#2178fb"
              stroke="#2178fb"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-bell"
            >
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
              <path d="M13.73 21a2 2 0 0 1-3.46 0" />
            </svg>
            <b-badge
              pill
              class="notificationBadge"
            >{{
              notificationCount(projectDtl.id)
            }}</b-badge>
          </span>
        </p>
        <feather-icon
          icon="ArrowRightCircleIcon"
          color="#2178fb"
          size="20"
        />
      </div>
      <div class="d-flex justify-content-between flex-column h-100">
        <div
          class="projectListBody"
          @click="projectSummary"
        >
          <b-row>
            <b-col
              cols="8"
              class="pr-0"
            >
              <p class="newproject-scop-member">
                Project Scope
              </p>
              <div v-if="projectDtl.project_scopes.length">
                <b-badge
                  v-for="(scope, index) in limitedScopes"
                  :key="index"
                  class="newproject-card-tag"
                >
                  <span class="tag_name">{{ scope }}</span>
                </b-badge>
                <span
                  v-if="projectScopes.length > 3"
                  v-b-tooltip.hover.top="scopesTooltip"
                  class="projecrMoretxt"
                >
                  + {{ remainingScopesCount }} More
                </span>
              </div>
              <div v-else>
                -
              </div>
            </b-col>
            <b-col
              cols="4"
              class="text-right"
            >
              <p class="newproject-scop-member">
                Cost Type
              </p>
              <div class="newproject-timeline-section">
                <b-badge
                  pill
                  :class="`newproject-card-timeline ${projectTypeClass}`"
                >
                  {{ projectTypeName }}
                </b-badge>
              </div>
            </b-col>
          </b-row>
          <b-row class="newproject-date-section">
            <b-col
              cols="6"
              class="px-0"
            >
              <p class="newproject-scop-member">
                Expected Start Date
              </p>
              <p class="newproject-timeline-date d-flex align-items-center">
                <feather-icon
                  icon="CalendarIcon"
                  size="16"
                  class="mr-50"
                />
                <span v-if="projectDtl.estimated_start_date">{{
                  projectDtl.estimated_start_date | timeDayFormat
                }}</span>
                <span v-else>N/A</span>
              </p>
            </b-col>
            <b-col
              cols="6"
              class="text-right pr-0"
            >
              <p class="newproject-scop-member">
                Expected End Date
              </p>
              <p class="newproject-timeline-date actual-time d-flex align-items-center justify-content-end">
                <feather-icon
                  icon="TargetIcon"
                  size="16"
                  class="mr-50"
                />
                <span v-if="projectDtl.estimated_end_date">{{
                  projectDtl.estimated_end_date | timeDayFormat
                }}</span>
                <span v-else>N/A</span>
              </p>
            </b-col>
          </b-row>
          <b-row class="newproject-date-section">
            <b-col
              cols="6"
              class="px-0"
            >
              <p class="newproject-scop-member">
                Actual Start Date
              </p>
              <p class="newproject-timeline-date d-flex align-items-center">
                <feather-icon
                  icon="CalendarIcon"
                  size="16"
                  class="mr-50"
                />
                <span v-if="projectDtl.actual_start_date">{{
                  projectDtl.actual_start_date | timeDayFormat
                }}</span>
                <span v-else>N/A</span>
              </p>
            </b-col>
            <b-col
              cols="6"
              class="text-right pr-0"
            >
              <p class="newproject-scop-member">
                Actual End Date
              </p>
              <p class="newproject-timeline-date actual-time d-flex align-items-center justify-content-end">
                <feather-icon
                  icon="TargetIcon"
                  size="16"
                  class="mr-50"
                />
                <span v-if="projectDtl.actual_end_date">{{
                  projectDtl.actual_end_date | timeDayFormat
                }}</span>
                <span v-else>N/A</span>
              </p>
            </b-col>
          </b-row>
          <b-row class="newproject-date-section">
            <b-col
              cols="7"
              class="px-0"
            >
              <p class="newproject-scop-member">
                Members
              </p>
              <div
                v-if="projectUsers.length"
                class="d-flex justify-content-start align-items-center"
              >
                <b-avatar
                  v-for="(member, index) in limitedProjectUsers"
                  :key="index"
                  v-b-tooltip.hover
                  size="26px"
                  class="newproject-avatar pull-up mb-0"
                  :title="member.project_user"
                  :src="member.picture"
                  :text="member.project_user | avatarText"
                  :variant="randomVariant(index, projectUsers.length)"
                />
                <span
                  v-if="projectUsers.length > 5"
                  v-b-tooltip.hover.top="membersTooltip"
                  class="projecrMembermore"
                >
                  {{ remainingMembersCount }}+
                </span>
              </div>
            </b-col>
            <b-col
              cols="5"
              class="text-right pr-0"
            >
              <p class="newproject-scop-member">
                Project Status
              </p>
              <div class="d-flex justify-content-end align-items-center newproject-timeline-section">
                <b-badge :class="`newproject-card-status ${projectStatusClass}`">
                  {{ projectStatusName }}
                </b-badge>
              </div>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="!projectTimeLoader"
          class="projectFooter"
        >
          <div
            class="newproject-date-section m-0 d-flex justify-content-between"
            style="flex: 1"
          >
            <div class="total-spent time-log-section projectSpent">
              <p class="userhour-log card-userlog">
                Time Spent
              </p>
              <p class="user-totalhour">
                {{ projectTimeSpent }}
              </p>
            </div>
            <div class="total-spent projectSpent">
              <p class="userhour-log card-userlog">
                Time Billable
              </p>
              <p class="user-totalhour">
                {{ projectTimeBillable }}
              </p>
            </div>
            <div>
              <div :class="maxTimeClass">
                <p class="userhour-log card-userlog">
                  Max Time Planned
                </p>
                <p class="user-totalhour">
                  {{ projectDtl.time_estimated | hoursInTimeFormatForSummary }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          v-else
          class="projectFooter"
        >
          <div
            class="newproject-date-section m-0 d-flex justify-content-between"
            style="flex: 1"
          >
            <div
              v-for="index in 3"
              :key="index"
              class="total-spent projectSpent"
            >
              <short-text
                v-for="innerIndex in 3"
                :key="innerIndex"
              />
            </div>
          </div>
        </div>

      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BBadge, BAvatar,
} from 'bootstrap-vue'
import ShortText from '../shimmer/shortText.vue'

export default {
  name: 'NewProjectCard',
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    ShortText,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    projectDtl: {
      type: Object,
      required: true,
    },
    pageNumber: {
      type: Number,
      required: true,
      default: 1,
    },
    perPage: {
      type: Number,
      required: true,
      default: 8,
    },
    projectUsersDtl: {
      type: Array,
      required: true,
      default: () => [],
    },
    projectTimeDtl: {
      type: Array,
      required: true,
      default: () => [],
    },
    projectTimeLoader: {
      type: Boolean,
      required: true,
      default: true,
    },
    allNotificationData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  computed: {
    projectTypeName() {
      const { type } = this.projectDtl
      const element = this.projectTypeList.find(el => el.value === type)
      return element ? element.label : ''
    },

    projectTimeSpent() {
      if (this.projectTimeDtl && this.projectTimeDtl.length) {
        const correspondingProject = this.projectTimeDtl.find(proj => proj.project_id === this.projectDtl.id)
        if (correspondingProject) {
          return this.$options.filters.convertMinutes(correspondingProject.total_time)
        }
      }
      return '00:00'
    },

    projectTimeBillable() {
      if (this.projectTimeDtl && this.projectTimeDtl.length) {
        const correspondingProject = this.projectTimeDtl.find(proj => proj.project_id === this.projectDtl.id)
        if (correspondingProject) {
          return this.$options.filters.convertMinutes(correspondingProject.billable_total)
        }
      }
      return '00:00'
    },

    projectTypeClass() {
      const typeClasses = {
        dedicated: 'dedicated',
        fix_cost: 'fixcost',
        fix_timeline: 'fixtimeline',
      }
      return typeClasses[this.projectDtl.type] || 'hourly'
    },

    projectStatusName() {
      const { status } = this.projectDtl
      const element = this.projectStatusList.find(el => el.value === status)
      return element ? element.label : ''
    },

    projectStatusClass() {
      const statusClasses = {
        in_planning: 'p-inplanning',
        in_progress: 'p-inprogress',
        on_hold: 'p-onhold',
        in_support: 'p-insupport',
      }
      return statusClasses[this.projectDtl.status] || 'p-completed'
    },

    projectScopes() {
      return this.projectDtl.project_scopes.split(',')
    },

    limitedScopes() {
      return this.projectScopes.slice(0, 3)
    },

    remainingScopesCount() {
      return this.projectScopes.length - 3
    },

    scopesTooltip() {
      return this.scopes(this.projectScopes)
    },

    projectUsers() {
      return this.projectUsersDtl.filter(
        user => user.project_id === this.projectDtl.id,
      )
    },

    limitedProjectUsers() {
      return this.projectUsers.slice(0, 5)
    },

    remainingMembersCount() {
      return this.projectUsers.length - 5
    },

    membersTooltip() {
      return this.members(this.projectUsers)
    },

    maxTimeClass() {
      return {
        'total-spent projectSpent': true,
        'maxTime-log':
          this.projectDtl.time_spent_minutes
          > this.projectDtl.time_estimated * 60,
        'minTime-log':
          this.projectDtl.time_spent_minutes
          <= this.projectDtl.time_estimated * 60,
      }
    },
  },
  methods: {
    projectSummary() {
      this.$store.commit('app/UPDATE_PROJECT_ID', this.projectDtl.id)
      this.$router.push({
        name: 'projectTask',
        params: {
          id: 'summary',
          id2: this.projectDtl.slug,
          id3: this.projectDtl.id,
        },
      }).catch(err => {
        // Ignore the NavigationDuplicated error
        if (err.name !== 'NavigationDuplicated') {
          console.log(err)
        }
      })
    },

    openNewTab() {
      const routeData = this.$router.resolve({
        path: `/task-time-log/${this.projectDtl.id}`,
      })
      window.open(routeData.href, '_blank')
    },

    members(arr) {
      return arr
        .slice(5)
        .map(item => item.project_user)
        .join(' ,')
    },

    scopes(arr) {
      return arr.slice(3).join(' ,')
    },

    notificationCount(id) {
      return this.allNotificationData.filter(
        item => item.data.project_id === id,
      ).length
    },

  },
}
</script>

<style>
@import url("../../assets/scss/component-css/projectcards.css");

.maxTime-log {
  background-color: rgba(234, 84, 85, 0.12) !important;
  padding: 10px 12px !important;
}
</style>
