<template>
  <div style="float: left width:100%">
    <div class="newsection-cards-header">
      <div class="d-flex align-items-center">
        <p class="listing-title">
          Projects Listing
        </p>
        <div class="ml-1 cursor-pointer sync-btn">
          <feather-icon
            :icon="view"
            color="#2178fb"
            size="20"
            @click="view = view == 'GridIcon' ? 'ListIcon' : 'GridIcon'"
          />
        </div>
      </div>
      <div class="d-flex align-items-center">
        <table-button
          v-if="(userInfo && userInfo.role == 'SA') ||
            (userInfo.role == 'EMP' && userInfo.is_resource_managing == 1)
          "
          v-b-toggle.addEditProject
          btn-name="Create New Project"
        />

        <Help
          v-show="$store.state.app.userManualList"
          code="LP"
        />
      </div>
    </div>
    <filter-section
      ref="listFilter"
      :per-pages="perPage"
    />

    <!-- grid view  start shimmer-->
    <b-row
      v-if="load_project && view == 'GridIcon'"
      class="d-flex"
    >
      <b-col
        v-for="col in 4"
        :key="col"
        md="12"
        xl="3"
        lg="6"
      >
        <card />
      </b-col>
    </b-row>
    <!-- grid view  end shimmer-->
    <div
      v-else-if="projectListingData.length && view == 'GridIcon'
      "
      class="d-flex flex-wrap"
    >
      <div
        v-for="(project, index) in projectListData"
        :key="index"
        class="py-1 cardProjectlist"
      >
        <new-project-card
          :project-dtl="project"
          :page-number="currentPage"
          :per-page="perPage"
          :project-users-dtl="projectUsers"
          :all-notification-data="allNotificationData"
          :project-time-dtl="projectTimeDtl"
          :project-time-loader="projectTimeLoader"
        />
      </div>
    </div>

    <div class="table horizontal-long-line">
      <b-row v-if="!load_project && view != 'GridIcon'">
        <b-col cols="12">
          <b-table
            id="table"
            striped
            hover
            responsive
            :items="projectListingData"
            :fields="fields"
            show-empty
            class="scrollable-element user-table-items tableDesigns mastertblColor table-responsive non-project-table"
          >
            <template #cell(description)="data">
              <p
                v-b-tooltip.hover.top
                class="project-desc-non d-inline-block"
                :title="data.item.description"
              >
                {{ data.item.description }}
              </p>
            </template>
            <template #cell(action)="data">
              <span class="action-edit-del">
                <feather-icon
                  icon="ArrowRightCircleIcon"
                  color="#2178fb"
                  size="20"
                  @click="projectSummary(data.item)"
                />
              </span>
            </template>
            <template #cell(status)="data">
              {{
                data.item.status ? getStatusLabel(data.item.status) : null
              }}
            </template>

            <template #cell(name)="data">
              <div class="d-flex align-items-center">
                <div>
                  <b-avatar
                    :text="data.item.name | avatarText"
                    :style="{ 'background-color': '#' + data.item.color }"
                    size="40"
                  />
                </div>
                <div class="media-body">
                  <p
                    class="custom-project"
                    style="margin-left: 5px"
                    :style="{ color: '#' + data.item.color }"
                  >
                    {{ data.item.name }}
                  </p>
                </div>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>

    <!-- pagination section -->
    <b-row v-if="totalCount > 0">
      <Pagination
        :current-page="$store.state.app.projectFilterList.currentPage"
        :per-page="$store.state.app.projectFilterList.perPage"
        :total-count="totalCount"
        @currentPage="currentPage = $event"
      />
    </b-row>
    <!-- pagination section complete here -->

    <b-row v-else>
      <img
        v-if="!load_project"
        src="../../assets/images/nodatafound/nodata.svg"
        alt="no-data"
        class="no-data-img"
      >
    </b-row>

    <project-table-form @projectList="projectList()" />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  VBToggle,
} from 'bootstrap-vue'

import ProjectTableForm from '@/@core/components/table-from/ProjectTableFrom.vue'
import Help from '@/components/HelpDesk/Help.vue'
import { eventBus } from '@/main'
import NewProjectCard from './NewProjectCard.vue'
import TableButton from '../../@core/components/buttons/TableButton.vue'
import FilterSection from './Filter.vue'
import Card from '../../components/loaders/card.vue'

export default {
  name: 'AllProject',
  components: {
    BRow,
    BCol,
    TableButton,
    ProjectTableForm,
    NewProjectCard,
    FilterSection,
    Card,
    Help,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      totalCount: null,
      currentPage: 1,
      perPage: 1,
      view: 'GridIcon',
      items: [],
      project: null,
      sort: 'desc',
      projects: [],
      projectListingData: [],
      applyFilter: false,
      load_project: false,
      filters: null,
      fields: [
        {
          key: 'name',
          label: 'Project Name',
          sortable: true,
          thStyle: { width: '300px' },
          tdStyle: { width: '300px' },
        },
        {
          key: 'description',
          label: 'Project Description',
        },
        {
          key: 'status',
          label: 'Project Status',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'action',
          label: 'Action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '120px' },
          tdStyle: { width: '120px' },
        },
      ],
      projectUsers: [],
      projectTimeDtl: [],
      projectIds: [],
      allNotificationData: [],
      projectTimeLoader: true,
    }
  },
  computed: {
    projectListData() {
      return this.projectListingData
    },
  },
  watch: {
    currentPage: {
      handler(value) {
        if (value && !this.applyFilter) {
          this.currentPage = value
          this.$store.state.app.projectFilterList.currentPage = value
          setTimeout(() => {
            this.projectList()
          }, 50)
        }
      },
    },

  },
  mounted() {
    this.currentPage = this.$store.state.app.projectFilterList
      && this.$store.state.app.projectFilterList.currentPage
      ? this.$store.state.app.projectFilterList.currentPage : 1
    eventBus.$on('projectFilters', data => {
      if (data) {
        this.perPage = data.perPage
        this.applyFilter = true
        // after clear fiter redirect first page
        this.currentPage = 1
        this.projectList(data)
      }
    })

    if (this.$route.params) {
      this.view = this.$route.params.list_icon
        ? this.$route.params.list_icon
        : 'GridIcon'
    }

    if (this.$store.state.app.projectFilterList) {
      this.filters = this.$store.state.app.projectFilterList
      if (this.currentPage == 1) {
        this.projectList(this.$store.state.app.projectFilterList)
      }
    }
  },
  destroyed() {
    eventBus.$off('projectSearch')
    eventBus.$off('projectFilters')
  },
  methods: {
    async projectList(filters = null) {
      filters = filters || this.filters
      this.load_project = true
      const input = {
        search: filters && filters.search ? filters.search : null,
        status:
          filters && filters.status && filters.status.length
            ? filters.status
            : [],
        user_id: filters && filters.user ? filters.user : null,
        type:
          filters && filters.type && filters.type.length ? filters.type : [],
        budget: filters && filters.budget ? filters.budget : null,
        page: this.$store.state.app.projectFilterList.currentPage || 1,
        per_page: this.$store.state.app.projectFilterList.perPage || 8,
        client_id: filters && filters.client ? filters.client : null,
        sort_order: filters && filters.sort ? (filters.sort || 'asc') : 'asc',
        sort_field: 'name',
      }
      const response = await this.getHTTPPostResponse(
        'project/all-project',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response

        this.projectListingData = data.projects
        this.projectUsers = data.project_user
        this.totalCount = data.count
        const ids = []
        data.projects.forEach(element => {
          ids.push(element.id)
        })
        this.projectIds = ids
        if (this.projectIds && this.projectIds.length) {
          this.projectNotificationList()
          this.getProjectUserList()
          this.getBillableAndTimeSpend()
        }

        this.applyFilter = false
        this.load_project = false
        this.filters = filters
      }
      if (this.project !== null) {
        this.$root.$emit('bv::toggle::collapse', 'fillter')
      }
      this.loading = false
      this.load_project = false
    },

    projectSummary(data) {
      this.$router.push({
        name: 'projectTask',
        params: {
          id: 'summary',
          id2: data.slug,
          id3: data.id,
          pageNumber: this.currentPage,
          perPage: this.perPage,
          listIcon: this.view,
        },
      })
    },

    // Get project Notification
    async projectNotificationList() {
      const input = {
        project_id: this.projectIds,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user/project-notification-list',
        input,
        false,
      )
      if (response && response.data) {
        this.notificationCount = response.data.count
        this.allNotificationData = response.data.notifications
      }
    },

    // Get project users
    async getProjectUserList() {
      const input = {
        project_id: this.projectIds,
      }
      const response = await this.getHTTPPostResponse(
        'project/all-project-user',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.projectUsers = data.project_user
      }
    },

    // Get project time spend & total billable
    async getBillableAndTimeSpend() {
      this.projectTimeLoader = true
      const input = {
        project_ids: this.projectIds,
      }
      const response = await this.getHTTPPostResponse(
        'project/project-time',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.projectTimeDtl = data.projects
      }
      this.projectTimeLoader = false
    },

    getStatusLabel(data) {
      switch (data) {
        case 'in_planning':
          return 'In Planning'
        case 'on_hold':
          return 'On Hold'
        case 'in_support':
          return 'In Support'
        case 'completed':
          return 'Completed'
        default:
          return 'In Progress'
      }
    },

  },
}
</script>

<style>
@import "../../assets/scss/component-css/allproject.css";
</style>
