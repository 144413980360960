var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-project-form"},[_c('validation-observer',{ref:"projectObserver"},[_c('b-sidebar',{staticClass:"sidebar-form",attrs:{"id":"addEditProject","bg-variant":"white","right":"","backdrop":"","shadow":""},on:{"hidden":_vm.sidebarToggle,"shown":_vm.sidebarToggle1},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"form-button d-flex my-2"},[_c('button',{staticClass:"btn side-bar-btn",attrs:{"type":"submit","disabled":_vm.btnLoader},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.btnLoader)?_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.id ? "Update" : "Add")+" Project ")],1),_c('button',{staticClass:"btn side-bar-btn",attrs:{"type":"submit","disabled":_vm.btnLoader},on:{"click":function($event){$event.preventDefault();return _vm.validationFormAndOpen.apply(null, arguments)}}},[(_vm.btnLoader)?_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}}):_vm._e(),_vm._v(" Add & Open Project ")],1),_c('button',{staticClass:"btn side-bar-btn",on:{"click":function($event){return _vm.clearData()}}},[_vm._v(" Clear ")])])]},proxy:true}])},[_c('h3',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.id ? "Edit" : "Add New")+" Project ")]),_c('div',{staticClass:"line",staticStyle:{"width":"200px"}}),_c('div',{staticClass:"b-sidebar-header"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("NAME "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Name","mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"project name","state":errors.length > 0 ? false : null,"placeholder":"Name","name":"project name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("PROJECT CODE "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Code","mode":"passive","rules":"required|max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"projectcode","type":"text","name":"projectcode","placeholder":"Project Code"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"label-for":"description"}},[_vm._v("Detail "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Description","mode":"passive","rules":"required|max:500"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":"Description","name":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Work Type","label-for":"work_type"}},[_c('validation-provider',{attrs:{"name":"Work type","mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-lg",attrs:{"name":"work_type","label":"name","options":_vm.workTypeList,"reduce":function (option) { return option.value; },"placeholder":"Select work type","disabled":true},model:{value:(_vm.workType),callback:function ($$v) {_vm.workType=$$v},expression:"workType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("COLOR "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Project color","mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"color-picker-input"},[_c('b-form-input',{attrs:{"id":"projectcolor","type":"text","name":"projectcolor","placeholder":"Select any color"},model:{value:(_vm.projectColor),callback:function ($$v) {_vm.projectColor=$$v},expression:"projectColor"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.projectColor),expression:"projectColor"}],staticClass:"color-picker",attrs:{"type":"color","name":"projectcolor"},domProps:{"value":(_vm.projectColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.projectColor=$event.target.value}}})])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.workType === 'productive')?_c('b-row',[_c('b-col',{staticClass:"light_line w-full",attrs:{"cols":"12"}}),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("PROJECT TYPE "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Project type","mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"projecttype","hide-details":"","name":"projecttype","label":"label","options":_vm.projectTypeList,"reduce":function (type) { return type.value; },"placeholder":"Select type"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1735196144)})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("STATUS "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Project status","mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"status","hide-details":"","name":"status","label":"label","options":_vm.projectStatusList,"reduce":function (status) { return status.value; },"placeholder":"Select status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3142444220)})],1),_c('b-col',{attrs:{"xl":"6","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"estimated_start_date"}},[_vm._v("Estimated start date")]),_c('validation-provider',{attrs:{"name":"Estimated start date","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.configEstimatedStartDate,"placeholder":"Estimated start date","name":"estimated_start_date"},on:{"on-change":_vm.onEstimatedStartDateChange,"on-open":function($event){return _vm.addclass()},"on-close":function($event){return _vm.removeclass()}},model:{value:(_vm.estimated_start_date),callback:function ($$v) {_vm.estimated_start_date=$$v},expression:"estimated_start_date"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3606672941)})],1)],1),_c('b-col',{attrs:{"xl":"6","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"estimated end date"}},[_vm._v("Estimated end date")]),_c('validation-provider',{attrs:{"name":"Estimated end date","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.configEstimatedEndDate,"placeholder":"Estimated end date","name":"estimated_end_date"},on:{"on-change":_vm.onEstimatedEndDateChange,"on-open":function($event){return _vm.addclass()},"on-close":function($event){return _vm.removeclass()}},model:{value:(_vm.estimated_end_date),callback:function ($$v) {_vm.estimated_end_date=$$v},expression:"estimated_end_date"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,573235394)})],1)],1),_c('b-col',{attrs:{"xl":"6","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"actual_start_date"}},[_vm._v("Actual start date")]),_c('validation-provider',{attrs:{"name":"Actual start date","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.configActualStartDate,"placeholder":"Actual start date","name":"actual_start_date"},on:{"on-change":_vm.onActualStartDateChange,"on-open":function($event){return _vm.addclass()},"on-close":function($event){return _vm.removeclass()}},model:{value:(_vm.actual_start_date),callback:function ($$v) {_vm.actual_start_date=$$v},expression:"actual_start_date"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,14002385)})],1)],1),_c('b-col',{attrs:{"xl":"6","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"actual_end_date"}},[_vm._v("Actual end date")]),_c('validation-provider',{attrs:{"name":"Actual end date","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.configActualEndDate,"placeholder":"Actual end date","name":"actual_end_date"},on:{"on-change":_vm.onActualEndDateChange,"on-open":function($event){return _vm.addclass()},"on-close":function($event){return _vm.removeclass()}},model:{value:(_vm.actual_end_date),callback:function ($$v) {_vm.actual_end_date=$$v},expression:"actual_end_date"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,973175742)})],1)],1)],1):_vm._e(),(_vm.workType === 'productive')?_c('b-row',[_c('b-col',{staticClass:"light_line w-full",attrs:{"cols":"12"}}),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-observer',{ref:"clientObserver"},[_c('div',{staticClass:"primary-skill-section"},[_c('span',{staticClass:"primary-skill-section-span"},[(_vm.clientShow === false)?_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"},on:{"click":function($event){_vm.clientShow = true}}}):_vm._e(),(_vm.clientShow === true)?_c('feather-icon',{attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){_vm.clientShow = false}}}):_vm._e()],1),_c('label',[_vm._v("CLIENT NAME "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.clientShow === false)?_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"client name","hide-details":"","name":"client_id","label":"name","options":_vm.clientListComputed,"reduce":function (client) { return client.id; },"placeholder":"Select Client"},model:{value:(_vm.client_id),callback:function ($$v) {_vm.client_id=$$v},expression:"client_id"}}):_vm._e(),(_vm.clientShow === false)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,3218997877)})],1),(_vm.clientShow === true)?_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Client Name","state":errors.length > 0 ? false : null,"placeholder":"Client Name","name":"Client Name"},model:{value:(_vm.clientName),callback:function ($$v) {_vm.clientName=$$v},expression:"clientName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2547043021)})],1):_vm._e(),(_vm.clientShow === true)?_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"country name","hide-details":"","name":"country","label":"name","options":_vm.countries,"reduce":function (country) { return country.id; },"placeholder":"Select Country"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3506214001)})],1):_vm._e(),_c('div',{staticClass:"form-button-new d-flex mt-1"},[(_vm.clientShow === true)?_c('button',{staticClass:"btn side-bar-btn",attrs:{"type":"submit","disabled":_vm.btnLoader},on:{"click":function($event){$event.preventDefault();return _vm.clientForm.apply(null, arguments)}}},[(_vm.btnLoader)?_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s("Add Client")+" ")],1):_vm._e()])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-observer',{ref:"projectScopeObserver"},[_c('div',{staticClass:"primary-skill-section"},[_c('span',{staticClass:"primary-skill-section-span"},[(_vm.projectScopeShow === false)?_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"},on:{"click":function($event){_vm.projectScopeShow = true}}}):_vm._e(),(_vm.projectScopeShow === true)?_c('feather-icon',{attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){_vm.projectScopeShow = false}}}):_vm._e()],1),_c('label',[_vm._v("PROJECT SCOPE "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Project Scope","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.projectScopeShow === false)?_c('v-select',{staticClass:"select-size-lg primary-skill-select",attrs:{"id":"project_scope_id","hide-details":"","name":"project_scope_id","label":"name","options":_vm.projectScopes,"reduce":function (scope) { return scope.id; },"placeholder":"Select project scope","close-on-select":false,"multiple":""},model:{value:(_vm.project_scope_id),callback:function ($$v) {_vm.project_scope_id=$$v},expression:"project_scope_id"}}):_vm._e(),(_vm.projectScopeShow === false)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,370314558)})],1),(_vm.projectScopeShow === true)?_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Project Scope","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Project scope","state":errors.length > 0 ? false : null,"placeholder":"Project scope","name":"Project scope"},model:{value:(_vm.projectScopeName),callback:function ($$v) {_vm.projectScopeName=$$v},expression:"projectScopeName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1248237482)})],1):_vm._e(),(_vm.projectScopeShow === true)?_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"color-picker-input"},[_c('b-form-input',{attrs:{"id":"scopeColor","type":"text","state":errors.length > 0 ? false : null,"name":"scopeColor","placeholder":"Select any color"},model:{value:(_vm.scopeColor),callback:function ($$v) {_vm.scopeColor=$$v},expression:"scopeColor"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.scopeColor),expression:"scopeColor"}],staticClass:"color-picker",attrs:{"type":"color","name":"scopecolor"},domProps:{"value":(_vm.scopeColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.scopeColor=$event.target.value}}})])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2291411330)})],1):_vm._e(),_c('div',{staticClass:"form-button-new d-flex mt-1"},[(_vm.projectScopeShow === true)?_c('button',{staticClass:"btn side-bar-btn",attrs:{"type":"submit","disabled":_vm.btnLoader},on:{"click":function($event){$event.preventDefault();return _vm.projectScopeForm.apply(null, arguments)}}},[(_vm.btnLoader)?_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s("Add Scope")+" ")],1):_vm._e()])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"time estimate","label-for":"Time Estimate"}},[_c('validation-provider',{attrs:{"name":"Time estimate","rules":"min_value:0|max_value:10000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"id":"timeestimate","append":"Hours"}},[_c('b-form-input',{attrs:{"name":"timeestimate","type":"number","placeholder":"Time Estimate"},model:{value:(_vm.time_estimate),callback:function ($$v) {_vm.time_estimate=$$v},expression:"time_estimate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2015332075)})],1)],1),(_vm.type === 'fix_cost')?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label-for":"estimate cost"}},[_c('label',{attrs:{"for":"actual_start_date"}},[_vm._v("estimate cost "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Estimate cost","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"id":"estimate cost","append":"euro"}},[_c('b-form-input',{attrs:{"name":"estimate cost","type":"number","placeholder":"Estimate Cost"},model:{value:(_vm.budget_estimated),callback:function ($$v) {_vm.budget_estimated=$$v},expression:"budget_estimated"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,959078335)})],1)],1):_vm._e(),(_vm.userInfo && _vm.userInfo.role != 'SA')?_c('b-col',{staticClass:"light_line w-full",attrs:{"cols":"12"}}):_vm._e(),(_vm.userInfo && _vm.userInfo.role != 'SA')?_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{staticClass:"assignmentTitle"},[_c('h3',[_vm._v("Add Assignment")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"actual_start_date"}},[_vm._v("Assignment date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Assignment start date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.configAssignmentStartDate,"name":"assignment_start_date","placeholder":"Select Month"},on:{"on-change":function($event){return _vm.setConfig('config')},"on-open":function($event){return _vm.addclass()},"on-close":function($event){return _vm.removeclass()}},model:{value:(_vm.assignment_start_date),callback:function ($$v) {_vm.assignment_start_date=$$v},expression:"assignment_start_date"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon","size":"20"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4278877062)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Time Plan","label-for":"Time Plan"}},[_c('validation-provider',{attrs:{"name":"Time plan","rules":"min_value:0|max_value:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"id":"time_plan","append":"Hours"}},[_c('b-form-input',{attrs:{"name":"time_plan","type":"number","placeholder":"Assign Hours"},on:{"input":function($event){return _vm.setConfig('setassignment')}},model:{value:(_vm.time_plan),callback:function ($$v) {_vm.time_plan=$$v},expression:"time_plan"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,990771101)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"label-for":"assignment"}},[_vm._v("Percentage "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Assignment","rules":"required|min_value:0|max_value:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"id":"assignment","type":"number","placeholder":"Assign percentage","max":3},on:{"input":function($event){return _vm.setConfig(_vm.index)}},model:{value:(_vm.assignment),callback:function ($$v) {_vm.assignment=$$v},expression:"assignment"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1860005654)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',[_c('b-form-group',{attrs:{"label":"billable","label-for":"assignment"}},[_c('div',{staticClass:"align-items-center text-center w-25"},[_c('div',{class:_vm.is_billable ? 'billable' : 'nonBillable'},[_c('span',{on:{"click":function($event){_vm.is_billable = !_vm.is_billable}}},[_vm._v("$")])])])])],1)])],1)],1)],1):_vm._e()],1):_vm._e()],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }