<template>
  <!-- this form is use in project table  -->
  <div class="custom-project-form">
    <validation-observer ref="projectObserver">
      <b-sidebar
        id="addEditProject"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-form"
        @hidden="sidebarToggle"
        @shown="sidebarToggle1"
      >
        <!--  form start -->

        <h3 class="form-title">
          {{ id ? "Edit" : "Add New" }} Project
        </h3>
        <div
          class="line"
          style="width: 200px"
        />

        <div class="b-sidebar-header">
          <!-- Name input -->
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <!-- <p class="new-smmarydetail-title mb-50">NAME <span class="text-danger">*</span></p>  -->
              <label>NAME <span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="Name"
                mode="passive"
                rules="required"
              >
                <b-form-input
                  id="project name"
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Name"
                  name="project name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <label>PROJECT CODE <span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="Code"
                mode="passive"
                rules="required|max:5"
              >
                <b-form-input
                  id="projectcode"
                  v-model="code"
                  type="text"
                  name="projectcode"
                  placeholder="Project Code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <!-- Description input -->
              <b-form-group>
                <label label-for="description">Detail <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  mode="passive"
                  rules="required|max:500"
                >
                  <b-form-textarea
                    id="description"
                    v-model="description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Description"
                    name="Description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- project detail complete here -->
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Work Type"
                label-for="work_type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Work type"
                  mode="passive"
                  rules="required"
                >
                  <v-select
                    v-model="workType"
                    name="work_type"
                    label="name"
                    :options="workTypeList"
                    :reduce="(option) => option.value"
                    class="select-size-lg"
                    placeholder="Select work type"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <!-- project color -->
              <label>COLOR <span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="Project color"
                mode="passive"
                rules="required"
              >
                <b-input-group class="color-picker-input">
                  <b-form-input
                    id="projectcolor"
                    v-model="projectColor"
                    type="text"
                    name="projectcolor"
                    placeholder="Select any color"
                  />
                  <b-input-group-append is-text>
                    <input
                      v-model="projectColor"
                      type="color"
                      name="projectcolor"
                      class="color-picker"
                    >
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="workType === 'productive'">
            <b-col
              cols="12"
              class="light_line w-full"
            />
            <b-col
              cols="12"
              md="6"
            >
              <label>PROJECT TYPE <span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="Project type"
                mode="passive"
                rules="required"
              >
                <v-select
                  id="projecttype"
                  v-model="type"
                  hide-details
                  name="projecttype"
                  label="label"
                  :options="projectTypeList"
                  :reduce="(type) => type.value"
                  class="select-size-lg"
                  placeholder="Select type"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <label>STATUS <span class="text-danger">*</span></label>
              <validation-provider
                #default="{ errors }"
                name="Project status"
                mode="passive"
                rules="required"
              >
                <v-select
                  id="status"
                  v-model="status"
                  hide-details
                  name="status"
                  label="label"
                  :options="projectStatusList"
                  :reduce="(status) => status.value"
                  class="select-size-lg"
                  placeholder="Select status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              xl="6"
              md="12"
            >
              <b-form-group>
                <label for="estimated_start_date">Estimated start date</label>
                <validation-provider
                  #default="{ errors }"
                  name="Estimated start date"
                  rules=""
                >
                  <b-input-group class="input-group-merge">
                    <flat-pickr
                      v-model="estimated_start_date"
                      class="form-control"
                      :config="configEstimatedStartDate"
                      placeholder="Estimated start date"
                      name="estimated_start_date"
                      @on-change="onEstimatedStartDateChange"
                      @on-open="addclass()"
                      @on-close="removeclass()"
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" /></b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              xl="6"
              md="12"
            >
              <b-form-group>
                <label for="estimated end date">Estimated end date</label>
                <validation-provider
                  #default="{ errors }"
                  name="Estimated end date"
                  rules=""
                >
                  <b-input-group class="input-group-merge">
                    <flat-pickr
                      v-model="estimated_end_date"
                      class="form-control"
                      :config="configEstimatedEndDate"
                      placeholder="Estimated end date"
                      name="estimated_end_date"
                      @on-change="onEstimatedEndDateChange"
                      @on-open="addclass()"
                      @on-close="removeclass()"
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              xl="6"
              md="12"
            >
              <b-form-group>
                <label for="actual_start_date">Actual start date</label>
                <validation-provider
                  #default="{ errors }"
                  name="Actual start date"
                  rules=""
                >
                  <b-input-group class="input-group-merge">
                    <flat-pickr
                      v-model="actual_start_date"
                      class="form-control"
                      :config="configActualStartDate"
                      placeholder="Actual start date"
                      name="actual_start_date"
                      @on-change="onActualStartDateChange"
                      @on-open="addclass()"
                      @on-close="removeclass()"
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              xl="6"
              md="12"
            >
              <b-form-group>
                <label for="actual_end_date">Actual end date</label>
                <validation-provider
                  #default="{ errors }"
                  name="Actual end date"
                  rules=""
                >
                  <b-input-group class="input-group-merge">
                    <flat-pickr
                      v-model="actual_end_date"
                      class="form-control"
                      :config="configActualEndDate"
                      placeholder="Actual end date"
                      name="actual_end_date"
                      @on-change="onActualEndDateChange"
                      @on-open="addclass()"
                      @on-close="removeclass()"
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="workType === 'productive'">
            <b-col
              cols="12"
              class="light_line w-full"
            />
            <b-col
              cols="12"
              md="6"
            >
              <validation-observer ref="clientObserver">
                <div class="primary-skill-section">
                  <span class="primary-skill-section-span"><feather-icon
                                                             v-if="clientShow === false"
                                                             icon="PlusIcon"
                                                             size="16"
                                                             @click="clientShow = true"
                                                           />
                    <feather-icon
                      v-if="clientShow === true"
                      icon="XIcon"
                      size="16"
                      @click="clientShow = false"
                    /></span>
                  <label>CLIENT NAME <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="Client"
                    rules="required"
                  >
                    <v-select
                      v-if="clientShow === false"
                      id="client name"
                      v-model="client_id"
                      hide-details
                      name="client_id"
                      label="name"
                      :options="clientListComputed"
                      :reduce="(client) => client.id"
                      class="select-size-lg"
                      placeholder="Select Client"
                    />
                    <small
                      v-if="clientShow === false"
                      class="text-danger"
                    >{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <!-- Client Name  -->
                <b-form-group v-if="clientShow === true">
                  <validation-provider
                    #default="{ errors }"
                    name="Client"
                    rules="required"
                  >
                    <b-form-input
                      id="Client Name"
                      v-model="clientName"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Client Name"
                      name="Client Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="clientShow === true">
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    rules="required"
                  >
                    <v-select
                      id="country name"
                      v-model="country"
                      hide-details
                      name="country"
                      label="name"
                      :options="countries"
                      :reduce="(country) => country.id"
                      class="select-size-lg"
                      placeholder="Select Country"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div class="form-button-new d-flex mt-1">
                  <button
                    v-if="clientShow === true"
                    class="btn side-bar-btn"
                    type="submit"
                    :disabled="btnLoader"
                    @click.prevent="clientForm"
                  >
                    <b-spinner
                      v-if="btnLoader"
                      small
                      class="spinner"
                    />
                    {{ "Add Client" }}
                  </button>
                </div>

              </validation-observer>
            </b-col>

            <!-- Project scope  -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-observer ref="projectScopeObserver">
                <div class="primary-skill-section">
                  <span class="primary-skill-section-span"><feather-icon
                                                             v-if="projectScopeShow === false"
                                                             icon="PlusIcon"
                                                             size="16"
                                                             @click="projectScopeShow = true"
                                                           />
                    <feather-icon
                      v-if="projectScopeShow === true"
                      icon="XIcon"
                      size="16"
                      @click="projectScopeShow = false"
                    /></span>
                  <label>PROJECT SCOPE <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="Project Scope"
                    rules="required"
                  >
                    <v-select
                      v-if="projectScopeShow === false"
                      id="project_scope_id"
                      v-model="project_scope_id"
                      hide-details
                      name="project_scope_id"
                      label="name"
                      :options="projectScopes"
                      :reduce="(scope) => scope.id"
                      class="select-size-lg primary-skill-select"
                      placeholder="Select project scope"
                      :close-on-select="false"
                      multiple
                    />
                    <small
                      v-if="projectScopeShow === false"
                      class="text-danger"
                    >{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <!-- Project scope input -->
                <b-form-group v-if="projectScopeShow === true">
                  <validation-provider
                    #default="{ errors }"
                    name="Project Scope"
                    rules="required"
                  >
                    <b-form-input
                      id="Project scope"
                      v-model="projectScopeName"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Project scope"
                      name="Project scope"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!--Project Scope Color start-->
                <b-form-group v-if="projectScopeShow === true">
                  <validation-provider
                    #default="{ errors }"
                    name="Color"
                    rules="required"
                  >
                    <b-input-group class="color-picker-input">
                      <b-form-input
                        id="scopeColor"
                        v-model="scopeColor"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        name="scopeColor"
                        placeholder="Select any color"
                      />
                      <b-input-group-append is-text>
                        <input
                          v-model="scopeColor"
                          type="color"
                          name="scopecolor"
                          class="color-picker"
                        >
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div class="form-button-new d-flex mt-1">
                  <button
                    v-if="projectScopeShow === true"
                    class="btn side-bar-btn"
                    type="submit"
                    :disabled="btnLoader"
                    @click.prevent="projectScopeForm"
                  >
                    <b-spinner
                      v-if="btnLoader"
                      small
                      class="spinner"
                    />
                    {{ "Add Scope" }}
                  </button>
                </div>
                <!--Project Scope color end-->
              </validation-observer>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="time estimate"
                label-for="Time Estimate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Time estimate"
                  rules="min_value:0|max_value:10000"
                >
                  <b-input-group
                    id="timeestimate"
                    append="Hours"
                    class="input-group-merge"
                  >
                    <b-form-input
                      v-model="time_estimate"
                      name="timeestimate"
                      type="number"
                      placeholder="Time Estimate"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="type === 'fix_cost'"
              cols="12"
              md="6"
            >
              <b-form-group
                label-for="estimate cost"
              >
                <label for="actual_start_date">estimate cost <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Estimate cost"
                  rules="required|min_value:0"
                >
                  <b-input-group
                    id="estimate cost"
                    append="euro"
                    class="input-group-merge"
                  >
                    <b-form-input
                      v-model="budget_estimated"
                      name="estimate cost"
                      type="number"
                      placeholder="Estimate Cost"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="userInfo && userInfo.role != 'SA'"
              cols="12"
              class="light_line w-full"
            />
            <b-col
              v-if="userInfo && userInfo.role != 'SA'"
              cols="12"
              md="12"
            >
              <b-form-group class="assignmentTitle">
                <h3>Add Assignment</h3>
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label for="actual_start_date">Assignment date <span class="text-danger">*</span></label>
                      <validation-provider
                        #default="{ errors }"
                        name="Assignment start date"
                        rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <flat-pickr
                            v-model="assignment_start_date"
                            :config="configAssignmentStartDate"
                            class="form-control"
                            name="assignment_start_date"
                            placeholder="Select Month"
                            @on-change="setConfig('config')"
                            @on-open="addclass()"
                            @on-close="removeclass()"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="CalendarIcon"
                              size="20"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Time Plan"
                      label-for="Time Plan"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Time plan"
                        rules="min_value:0|max_value:200"
                      >
                        <b-input-group
                          id="time_plan"
                          class="input-group-merge"
                          append="Hours"
                        >
                          <b-form-input
                            v-model="time_plan"
                            name="time_plan"
                            type="number"
                            placeholder="Assign Hours"
                            @input="setConfig('setassignment')"
                          />
                        </b-input-group>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group>
                      <label label-for="assignment">Percentage <span class="text-danger">*</span></label>
                      <validation-provider
                        #default="{ errors }"
                        name="Assignment"
                        rules="required|min_value:0|max_value:100"
                      >
                        <b-input-group
                          class="input-group-merge"
                          append="%"
                        >
                          <b-form-input
                            id="assignment"
                            v-model="assignment"
                            type="number"
                            placeholder="Assign percentage"
                            :max="3"
                            @input="setConfig(index)"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <!-- TODO: add is_billable field -->
                    <!-- is billable start -->
                    <div>
                      <b-form-group
                        label="billable"
                        label-for="assignment"
                      >
                        <div class="align-items-center text-center w-25">
                          <div :class="is_billable ? 'billable' : 'nonBillable'">
                            <span @click="is_billable = !is_billable">$</span>
                          </div>
                        </div>
                      </b-form-group>
                      <!-- {{ items[index].is_billable }} -->
                    </div>
                    <!-- is billable end -->
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- billing infromation comeplete here -->
        </div>
        <!-- form footer -->
        <template #footer>
          <div class="form-button d-flex my-2">
            <button
              class="btn side-bar-btn"
              type="submit"
              :disabled="btnLoader"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="btnLoader"
                small
                class="spinner"
              />
              {{ id ? "Update" : "Add" }} Project
            </button>
            <button
              class="btn side-bar-btn"
              type="submit"
              :disabled="btnLoader"
              @click.prevent="validationFormAndOpen"
            >
              <b-spinner
                v-if="btnLoader"
                small
                class="spinner"
              />
              Add & Open Project
            </button>
            <button
              class="btn side-bar-btn"
              @click="clearData()"
            >
              Clear
            </button>
          </div>
        </template>
      </b-sidebar></validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { eventBus } from '@/main'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import * as moment from 'moment/moment'

export default {
  name: 'ClientTableForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      task_number_preference: null,
      code: null,
      project_types: [],
      project_status: [],
      id: null,
      name: null,
      client_id: null,
      description: null,
      estimated_start_date: null,
      estimated_end_date: null,
      actual_start_date: null,
      actual_end_date: null,
      type: null,
      status: null,
      consider_project_rate: null,
      estimate_type: null,
      billable_rate: null,
      time_estimate: null,
      budget_estimated: null,
      is_billable: true,
      configEstimatedStartDate: {
        minDate: null,
        maxDate: null,
        dateFormat: 'Y/m/d',
      },
      configEstimatedEndDate: {
        minDate: null,
        minDate: null,
        dateFormat: 'Y/m/d',
      },
      configActualStartDate: {
        minDate: null,
        maxDate: null,
        dateFormat: 'Y/m/d',
      },
      configActualEndDate: {
        minDate: new Date(),
        minDate: null,
        dateFormat: 'Y/m/d',
      },
      considerProjectRates: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
      ],
      estimateValues: [
        { name: 'No', value: 'no' },
        { name: 'Time', value: 'time' },
        { name: 'Budget', value: 'budget' },
      ],
      configAssignmentStartDate: {
        //   minDate: new Date(),
        maxDate: null,
        dateFormat: 'Y-m-d',
        mode: 'range',
      },
      assignment: null,
      time_plan: null,
      assignment_start_date: null,
      projectColor: null,
      project: null,
      emailValue: '',

      email: '',

      projecttypeValue: null,
      considerRateValue: null,
      estimateValue: null,
      isbillableValue: null,
      projectType: null,
      message: null,
      btnLoader: false,
      workType: 'productive',
      project_scope_id: [],
      projectScopes: [],
      projectScopeShow: false,
      scopeColor: null,
      projectScopeName: null,
      clientShow: false,
      clientName: null,
      country: null,
      countries: [],
    }
  },

  computed: {

    clientListComputed() {
      return this.$store.state.app.clientList.filter(client => client.is_active === true)
    },
  },
  watch: {
    status() {
      if (this.id === null) {
        if (this.status === 'NS') {
          this.configEstimatedStartDate.minDate = new Date()
          this.configEstimatedEndDate.minDate = new Date()
          // this.configActualStartDate.minDate = new Date();
        } else {
          this.configEstimatedStartDate.minDate = null
          this.configEstimatedEndDate.minDate = null
          // this.configActualStartDate.minDate = null;
        }
      }
    },
  },
  mounted() {
    if (
      this.userInfo.role === 'SA'
      || this.userInfo.role === 'MN'
      || this.userInfo.is_resource_managing
    ) {
      this.getCountryData()
      this.getClientData()
      this.getProjectScopes()
    }
    // this.getProjectTypeData();
    eventBus.$on('edit-project', id => {
      if (id) {
        this.getProjectDetails(id)

        this.getProjectTypeData()
        this.getProjectStatusData()
      }
    })
  },
  destroyed() {
    eventBus.$off('edit-project')
  },
  methods: {
    addclass() {
      // Get the .b-sidebar-body element
      const sidebarBody = document.querySelector('.b-sidebar-body')

      // Add the new class
      sidebarBody.classList.add('overflow-hidden')
    },
    removeclass() {
      const sidebarBody = document.querySelector('.b-sidebar-body')

      // remove the  class
      sidebarBody.classList.remove('overflow-hidden')
    },

    /**
     * clear from detail
     */
    sidebarToggle() {
      this.id = null
      this.considerRateValue = null
      this.project = null
      this.name = null
      this.name = null
      this.type = null
      this.code = null
      this.projectColor = null
      this.task_number_preference = null
      this.client_id = null
      this.description = null
      this.estimated_start_date = null
      this.estimated_end_date = null
      this.actual_start_date = null
      this.actual_end_date = null
      this.type = null
      this.consider_project_rate = null
      this.estimate_type = null
      this.billable_rate = null
      this.workType = 'productive'
      // this.project_rate = null;
      this.time_estimate = null
      this.budget_estimated = null
      this.is_billable = true
      this.project_scope_id = []
      this.$refs.projectObserver.reset()
      document.body.classList.remove('overflow-hidden')
    },
    sidebarToggle1() {
      document.body.classList.add('overflow-hidden')
    },
    /**
     * Minimum Date config EstimatedStartDate
     * @returns Estimate start date
     */
    onEstimatedStartDateChange(selectedDates, dateStr, instance) {
      this.$set(this.configEstimatedEndDate, 'minDate', dateStr)
    },
    /**
     * Maximum Date EstimatedEndDate
     * @returns Estimate End date
     */
    onEstimatedEndDateChange(selectedDates, dateStr, instance) {
      this.$set(this.configEstimatedStartDate, 'maxDate', dateStr)
    },
    /**
     * Minimum Date ActualStartDate
     * @returns Actual start date
     */
    onActualStartDateChange(selectedDates, dateStr, instance) {
      this.$set(this.configActualEndDate, 'minDate', dateStr)
    },
    /**
     * Maximum Date ActualEndDate
     * @returns Actual end date
     */
    onActualEndDateChange(selectedDates, dateStr, instance) {
      this.$set(this.configActualStartDate, 'maxDate', dateStr)
    },
    /**
     * Get Client data
     * @returns clients (variable)
     */
    async getClientData() {
      const input = {
        is_active: 1,
        // status: 'joined'
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/client',
        input,
        false,
      )
      if (response && response.data) {
        this.$store.commit('app/UPDATE_CLIENT_LIST', response.data.clients)
      }
    },
    /**
     * Get Countries data
     * @returns Countries (variable)
     */
    async getCountryData() {
      if (
        this.userInfo.role === 'SA'
        || this.userInfo.is_resource_managing
      ) {
        const input = {
          sort_field: 'name',
          sort_order: 'asc',
        }
        const response = await this.getHTTPPostResponse(
          'admin/master/client/countries-list',
          input,
          false,
        )
        if (response && response.data) {
          const { data } = response
          this.countries = data && data.countries ? data.countries : []
        }
      }
    },

    /**
     * Add & Update the clientTableForm details & call client listing in clienttable
     * @emits clientList
     * @returns client detail add & update
     */
    async saveClientDtl() {
      if (!this.clientName && !this.country) {
        return
      }
      const input = {
        name: this.clientName,
        country_id: this.country,
      }
      let response = null

      response = await this.getHTTPPostResponse(
        'admin/master/client/create',
        input,
        true,
      )

      if (response && response.status === 200) {
        this.getClientData()
        this.clientShow = false
        this.client_id = response.data.client.id
        this.clientName = null
        this.country = []
      }
      this.btnLoader = false
    },

    /**
     * Add & Update the projectScopeForm details & call projectScope listing in projectScopetable
     * @emits projectScopeList
     * @returns projectScope detail add & update
     */
    async saveProjectScopeDtl() {
      if (!this.scopeColor && !this.projectScopeName) {
        return
      }
      const color = this.scopeColor.substring(1)
      const input = {
        name: this.projectScopeName,
        color,
      }
      let response = null
      response = await this.getHTTPPostResponse(
        'admin/master/project-scope/create',
        input,
        true,
      )

      if (response && response.status === 200) {
        this.getProjectScopes()
        this.projectScopeShow = false
        this.project_scope_id = response.data.project_scope.id
        this.projectScopeName = null
        this.scopeColor = null
      }
      this.btnLoader = false
    },
    /**
     * Get project Scope list
     * @returns projectScopes (variable)
     */
    async getProjectScopes() {
      const input = {
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/project-scope',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.projectScopes = data.count ? data.project_scopes : []

        /** used in project detail */
        this.$store.commit('app/UPDATE_PROJECT_SCOPE', this.projectScopes)
      }
    },
    /**
     * Get project type data
     * @returns project_types (variable)
     */
    async getProjectTypeData() {
      const input = {
        is_active: 1,
        // status: 'joined'
      }
      const response = await this.getHTTPPostResponse(
        'project/task',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.project_types = data.count ? data.project_types : []
      }
    },
    /**
     * Get project status data
     * @return project_status(variable)
     */
    async getProjectStatusData() {
      const input = {
        is_active: 1,
        // status: 'joined'
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/project-status',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.project_status = data.count ? data.project_statuses : []
      }
    },

    /*  set config of time plan & assignment base on date or other condition */
    setConfig(action) {
      /*   date seperate  */
      const dateArr = this.assignment_start_date.split(' to ')

      if (dateArr.length === 2) {
        const startDate = dateArr[0]
        const endDate = dateArr[1] ? dateArr[1] : dateArr[0]

        /*  set min & max date based on startDate */
        const minDate = moment(startDate).startOf('month').format('YYYY-MM-DD')
        const maxdate = moment(startDate).endOf('month').format('YYYY-MM-DD')
        let calcBusinessDays = 1 + moment(endDate).diff(moment(startDate), 'days') // selected dates
        calcBusinessDays = calcBusinessDays > 30 ? 30 : calcBusinessDays
        const monthdays = moment(startDate).endOf('month').format('MM') === '02'
          ? 1 + moment(maxdate).diff(moment(minDate), 'days')
          : 30

        switch (action) {
          case 'config':
            this.$set(this.configAssignmentStartDate, 'maxDate', maxdate)
            this.$set(this.configAssignmentStartDate, 'minDate', minDate)

            /*  check assignment value issue */
            this.assignment = this.assignment ? this.assignment : '100'

            /*  set or update hours as per assignment  >> already value >> default set */
            this.time_plan = this.assignment
              ? (
                ((this.assignment * 160) / (100 * monthdays))
                * calcBusinessDays
              ).toFixed(2)
              : this.time_plan
                ? this.time_plan
                : ((160 / (monthdays + 1)) * calcBusinessDays).toFixed(2)
            break
          case 'setassignment':
            this.assignment = (
              (this.time_plan * 100 * monthdays)
              / (160 * calcBusinessDays)
            ).toFixed(2)
            break

          default:
            this.time_plan = (
              ((this.assignment * 160) / (100 * monthdays))
              * calcBusinessDays
            ).toFixed(2)
        }
        this.time_plan = this.time_plan > 160 ? 160 : this.time_plan
        this.assignment = this.assignment > 100 ? 100 : this.assignment
      }
    },
    /**
     * Get project details from id
     * @returns Project Details
     */
    async getProjectDetails(id) {
      const response = await this.getHTTPPostResponse(
        `project/view/${id}`,
        {},
        false,
      )
      if (response && response.data && response.data.project) {
        const data = response.data.project
        this.id = data.id
        this.code = data.code
        this.task_number_preference = data.task_number_preference
        this.name = data.name
        this.client_id = data.client_id
        this.description = data.description
        this.estimated_start_date = data.estimated_start_date
        // this.projectColor = data.color;
        this.estimated_end_date = data.estimated_end_date
        this.actual_start_date = data.actual_start_date
        this.actual_end_date = data.actual_end_date
        this.type = data.type
        this.projectColor = `#${data.color}`
        this.considerRateValue = data.consider_project_rate ? 'Yes' : 'No'
        this.estimate_type = data.estimate_type
        this.billable_rate = data.billable_rate
        // this.project_rate = data.billable_rate;
        this.project = data.is_public === 1 ? 'Public' : 'Private'
        this.time_estimate = data.time_estimated
        this.budget_estimated = data.budget_estimated
        this.is_billable = !!data.is_billable
        this.status = data.status
        this.project_types = data.count ? data.project_types : []
        this.workType = data.work_type
        this.$root.$emit('bv::toggle::collapse', 'addEditProject')
      }
    },
    /**
     * check the form validation
     * @returns if success true then call saveProjectDtl Method
     */
    validationForm() {
      this.$refs.projectObserver.validate().then(success => {
        if (success) {
          this.saveProjectDtl('add')
        }
      })
    },
    validationFormAndOpen() {
      this.$refs.projectObserver.validate().then(success => {
        if (success) {
          this.saveProjectDtl('open')
        }
      })
    },
    projectScopeForm() {
      this.$refs.projectScopeObserver.validate().then(success => {
        if (success) {
          this.saveProjectScopeDtl()
        }
      })
    },
    clientForm() {
      this.$refs.clientObserver.validate().then(success => {
        if (success) {
          this.saveClientDtl()
        }
      })
    },
    /**
     * Add & Update the project-form details & call project listing in project-table
     * @emits projectList
     * @returns project detail add & update
     */
    async saveProjectDtl(addOpen) {
      this.btnLoader = true
      const color = this.projectColor.substring(1)
      const input = {
        name: this.name,
        client_id: this.client_id,
        description: this.description,
        estimated_start_date: this.estimated_start_date,
        estimated_end_date: this.estimated_end_date,
        actual_start_date: this.actual_start_date,
        actual_end_date: this.actual_end_date,
        type: this.type,
        time_estimated: this.time_estimate,
        budget_estimated: this.budget_estimated,
        status: this.status,
        code: this.code,
        color,
        work_type: this.workType,
        project_scope_id: this.project_scope_id,
      }

      let response = null
      if (this.id) {
        response = await this.getHTTPPutResponse(
          `project/update/${this.id}`,
          input,
          true,
        )
      } else {
        response = await this.getHTTPPostResponse(
          'project/create',
          input,
          true,
        )
      }
      if (response && response.status === 200) {
        if (this.userInfo.role != 'SA') {
          const dateArr = this.assignment_start_date.split(' to ')
          const input = {
            plan: [{
              project_id: response.data.project.id,
              user_id: this.userInfo.id,
              access_rights: 'project_owner',
              createType: 'project_user',
              time_plan: this.time_plan,
              assignment: this.assignment,
              expected_assignment_start_date: dateArr[0],
              expected_assignment_end_date: dateArr[1]
                ? dateArr[1]
                : moment(dateArr[0]).format('YYYY-MM-DD'),
              month: moment(dateArr[0]).format('MM'),
              year: moment(dateArr[0]).format('YYYY'),
              planned_hours: this.time_plan,
              is_billable: this.is_billable,
            }],
          }
          await this.getHTTPPostResponse(
            'project-resource/create-plan-user',
            input,
          )
        }
        if (addOpen === 'add') {
          this.$root.$emit('bv::toggle::collapse', 'addEditProject')
          this.$emit('projectList', true)
          setTimeout(() => {
            this.btnLoader = false
          }, 200)
        } else {
          // this.$router.push(`/projects/${response.data.project.id}/summary`)
          this.$router.push({
            name: 'projectTask',
            params: {
              id: 'summary',
              id2: response.data.project.name,
              id3: response.data.project.id,
            },
          })
        }
      }
      this.btnLoader = false
    },
    /**
     * Clear form details
     */
    clearData() {
      this.id = null
      this.name = null
      this.name = null
      this.code = null
      this.color = null
      this.task_number_preference = null
      this.client_id = null
      this.description = null
      this.estimated_start_date = null
      this.estimated_end_date = null
      this.actual_start_date = null
      this.actual_end_date = null
      this.type = null
      this.consider_project_rate = null
      this.estimate_type = null
      this.billable_rate = null
      this.time_estimate = null
      this.type = null
      this.budget_estimated = null
      this.considerRateValue = null
      this.project = null
      this.is_billable = true
      this.projectColor = null
      this.project_scope_id = []
      this.clientName = null
      this.country = null
      this.scopeColor = null
      this.projectScopeName = null
      this.time_plan = null
      this.assignment = null
      this.assignment_start_date = null;
      (this.status = null), this.$refs.projectObserver.reset()
    },
  },
}
</script>

<style lang="scss">
.primary-skill-select .vs__dropdown-toggle {
  padding: 5px !important;
}

.primary-skill-select .vs__dropdown-toggle .vs__selected {
  padding: 3px !important;
  margin-right: 2px !important;
  background-color: #2178fb;
  color: #ffff;
  font-weight: 600;
  margin-bottom: 2px !important;
}

.primary-skill-select .vs__dropdown-toggle .vs__selected .vs__deselect {
  fill: #ffff;
}

.assignmentTitle h3 {
  color: #5e5873;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 2px solid #5e5873;
  display: inline-block;
  padding-bottom: 8px;
}
.billable {
  font-size: 22px;
  font-weight: 700;
  color: #2178fb !important;
  cursor: pointer;
  b {
    color:  #2178fb !important;
  }
}
.nonBillable {
  font-size: 22px;
  font-weight: 700;
  color: grey !important;
  cursor: pointer;
}
</style>
